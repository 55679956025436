import { createAsyncThunk } from '@reduxjs/toolkit';
import { serialize } from 'object-to-formdata';
import queryString from 'query-string';
import apiKey from '../../../utils/axiosInstance';
export const getCabinet = createAsyncThunk('cabinet/getCabinet', async ({ search, limit, page, archived, startDate, endDate, }, ThunkApi) => {
    try {
        const query = queryString.stringify(!search
            ? { limit, page, archived, startDate, endDate }
            : startDate && endDate
                ? { search, limit, page, archived, startDate, endDate }
                : { search, limit, page, archived });
        const response = await apiKey.get(`/cabinets?${query}`);
        const data = response.data;
        return data;
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const createCabinet = createAsyncThunk('cabinet/createCabinet', async ({ params }, ThunkApi) => {
    try {
        const response = await apiKey.post(`/cabinets`, params);
        const data = response.data;
        return data;
    }
    catch (error) {
        return Promise.reject(error.message ? error.message : 'something went wrong');
    }
});
export const deleteCabinet = createAsyncThunk('cabinet/deleteCabinet', async ({ id }, ThunkApi) => {
    try {
        return await apiKey.delete(`/cabinets/${id}`);
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const getCabinetById = createAsyncThunk('cabinet/getCabinetById', async ({ id }, ThunkApi) => {
    try {
        const response = await apiKey.get(`/cabinets/${id}`);
        return response;
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const archiveCabinet = createAsyncThunk('cabinet/archiveCabinet', async ({ id }, ThunkApi) => {
    try {
        return await apiKey.post(`/cabinets/${id}/archive`);
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const unArchiveCabinet = createAsyncThunk('cabinet/unArchiveCabinet', async ({ id }, ThunkApi) => {
    try {
        return await apiKey.post(`/cabinets/${id}/unarchive`);
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const updateCabinet = createAsyncThunk('cabinet/updateCabinet', async ({ id, data }, ThunkApi) => {
    try {
        const response = await apiKey.put(`/cabinets/${id}`, serialize({ ...data }));
        if (response.status === 200) {
            return response.data;
        }
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : 'Rejeté');
    }
});
export const updateCabinetInfo = createAsyncThunk('cabinet/updateCabinet', async ({ id, params }, ThunkApi) => {
    try {
        const response = await apiKey.put(`/cabinets/${id}`, serialize({ ...params }));
        return response?.data;
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : 'Rejeté');
    }
});
